<template>
  <div>
    <router-link class="come-back" to="/admin/commissionUpdate">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Изменение в составе ОНК' : 'Создание изменение в составе ОНК' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="test" ref="creteСommissionUpdate">
      <div>
        <div class="content-sb">
          <p>Короткое сообщение об изменении<span>*</span></p>
          <input required placeholder="Введите название законопроекта" v-model="editData.shortText" type="text" name="shortText" class="input blue">
        </div>
        <div class="content-sb">
          <p>Статус<span>*</span></p>
          <select  @change="changeStatusDraft" ref="changeStatusDraft" required class="input blue"  name="type">
            <option disabled>Выберите статус законопроекта</option>
            <option v-for="item in $store.getters.getStatusCommissionUpdate"
                    :key="`getStatusCommissionUpdate${item.code}`"
                    :selected="editData.type === item.title"
                    :value="item.code">
              {{ item.title }}</option>
          </select>
        </div>
        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required  type="datetime-local" v-model="editData.date" name="date" class="input blue">
        </div>
        <div class="content-sb">
          <p>Текст</p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="editData.text"></ckeditor>
        </div>
        <div class="content-sb">
          <p>Теги</p>
          <tags @dataTags="dataTags" :startValue="editData.tagList" />
        </div>
      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/commissionUpdate" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'createСommissionUpdate',
  data () {
    return {
      editData: this.$route.query.type === 'edit' ? this.$store.getters.getDetailCommissionUpdate : {
        tagList: [],
        type: '',
        shortText: '',
        text: '',
        date: ''
      }
    }
  },
  methods: {
    test () {
      const formData = new FormData(this.$refs.creteСommissionUpdate)
      formData.append('text', this.editData.text)
      formData.append('tagList', JSON.stringify(this.editData.tagList))
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editCommissionUpdate', { id: this.editData.id, data: formData })
      } else {
        this.$store.dispatch('AddCommissionUpdate', formData)
      }
    },
    changeStatusDraft (e) {
      this.editData.state = e.target.value
    },
    dataTags (value) {
      this.editData.tagList = value
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailCommissionUpdate === null) {
      this.$router.push('/admin/commissionUpdate')
    }
    this.$store.dispatch('setStatusCommissionUpdate')
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
